import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import SheetTrGrouped from "./SheetTrGrouped";
import moment from 'moment';

const PdfButton = props => {

  const { groupedTr, getSpotName, getVehicleName } = props;
  const today = moment().format("YYYY-MM-DD");
  
  const generatePdf = week => {
    const fileName = `tr-declare-${today}.pdf`;
    const blob = pdf(
      <>
      <SheetTrGrouped today={today} groupedTr={groupedTr} getVehicleName={getVehicleName} getSpotName={getSpotName}/>
      </>
    ).toBlob();
    blob.then(pdfBlob => {
      saveAs(pdfBlob, fileName);
    });
  };

 
  return (
    <Button onClick={() => generatePdf()}>Générer fiche sur la journée</Button>
  );
};

export default PdfButton;
