import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import Layout from "views/universal/Layout/Layout";
import AddPoolPage from "./AddPoolPage/AddPoolPage"
import DepositValidationPage from "./DepositValidationPage/DepositValidationPage"
import SuspectPoolCustomerPage from "./SuspectPoolCustomerPage/SuspectPoolCustomerPage"
import { ticketRestaurantOperations, ticketRestaurantSelector, } from "state/ducks/store/ticketRestaurant";
import { Tabs, Tab } from "react-bootstrap";
import PopupPool from "./PopupPool"
import PopupAdvantage from "./PopupAdvantage"
import Customers from "views/pages/Store/Customers/Customers"

const TicketRestaurant = (props) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);
  const [isAdvantageModalOpen, setIsAdvantageModalOpen] = useState(false);
  const [isLoadingAdvantagePopup, setIsLoadingAdvantagePopup] = useState(false);

  const establishment = useSelector(
    (state) => state.session.establishment,
    shallowEqual
  );

  const search = useSelector(
    (state) => state.ticketRestaurant.managePage.search,
    shallowEqual
  );

  const customerPool = useSelector((state) => {
    return ticketRestaurantSelector.getCustomerPoolSelector(state);
  });

  const customerAdvantage = useSelector((state) => {
    return ticketRestaurantSelector.getCustomerAdvantageSelector(state);
  });

  // Recupère les stocks avec le selector par client
  const ticketsRestaurantsPage = useSelector((state) => {
    return ticketRestaurantSelector.getManagePageSelector(state);
  });

  // Récupere les tickets de maniere unitaire
  const ticketsRestaurants = useSelector((state) => {
    return ticketRestaurantSelector.managePageState(state);
  });
  
  const dispatch = useDispatch();

  useEffect(() => {
    if(establishment){
      dispatch(ticketRestaurantOperations.initPage(establishment));
    }
  }, [dispatch, establishment]);

  // Rcuperer historique du porte-monnaie
  const getCustomerPool = (customerId, e) => {
    e.preventDefault();
    setIsModalOpen(true);
    setIsLoadingPopup(true);
    dispatch(ticketRestaurantOperations.getCustomerPool(customerId)).then(
      (result) => {
        setIsLoadingPopup(false);
      },
      (error) => {
        setIsModalOpen(false);
        setIsLoadingPopup(false);
      }
    )
  };

  // Rcuperer historique du porte-monnaie
  const getCustomerAdvantage = (customerId, e) => {
    e.preventDefault();
    setIsAdvantageModalOpen(true);
    setIsLoadingAdvantagePopup(true);
    dispatch(ticketRestaurantOperations.getCustomerAdvantage(customerId)).then(
      (result) => {
        setIsLoadingAdvantagePopup(false);
      },
      (error) => {
        setIsAdvantageModalOpen(false);
        setIsLoadingAdvantagePopup(false);
      }
    )
  };

  return (
    <Layout location={props.location.search}>
      <Container>
        <Tabs defaultActiveKey="customer" fill mountOnEnter>
          <Tab eventKey="customer" title="Clients">
              {<Customers
                getCustomerPool={getCustomerPool}
                getCustomerAdvantage={getCustomerAdvantage}
              />}
          </Tab>
          <Tab eventKey="depot" title="Dépot TR à valider">
              {<DepositValidationPage
                establishment={establishment}
                vehicles={ticketsRestaurantsPage.vehicles}
                spots={ticketsRestaurantsPage.spots}
                trByCustomer={ticketsRestaurantsPage.ticketRestaurants}
                search={search}
                isLoadingPage={ticketsRestaurantsPage.isLoading}
                getCustomerPool={getCustomerPool}
                tr={ticketsRestaurants}
              />}
          </Tab>
          <Tab eventKey="credit" title="Opération TR manuelle">
              <div className="alert alert-danger mt-3"><b>INFORMATION IMPORTANTE :</b> les avoirs SAV ne sont plus ajoutés dans le porte-monnaie numérique mais dans la cagnotte avantages. AUCUN SAV ICI.</div>
              <AddPoolPage
                establishment={establishment}
                customers={ticketsRestaurantsPage.customers}
                isLoadingPage={ticketsRestaurantsPage.isLoading}
                getCustomerPool={getCustomerPool}
                operationType='credit'
              />
              <AddPoolPage
                establishment={establishment}
                customers={ticketsRestaurantsPage.customers}
                isLoadingPage={ticketsRestaurantsPage.isLoading}
                getCustomerPool={getCustomerPool}
                operationType='debit'
              />
          </Tab>
          <Tab eventKey="suspect" title="Porte-monnaie à surveiller">
            <SuspectPoolCustomerPage getCustomerPool={getCustomerPool} />
          </Tab>
        </Tabs>
      </Container>

      <PopupPool
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        onEscapeKeyDown={() => setIsModalOpen(false)}
        customerPool={customerPool}
        isLoading={isLoadingPopup}
      />

      <PopupAdvantage
        show={isAdvantageModalOpen}
        onHide={() => setIsAdvantageModalOpen(false)}
        onEscapeKeyDown={() => setIsAdvantageModalOpen(false)}
        customerAdvantage={customerAdvantage}
        isLoading={isLoadingAdvantagePopup}
      />

    </Layout>
  );
};

const Container = styled.div`
  padding: 1rem;
`;

TicketRestaurant.propTypes = {};

TicketRestaurant.defaultProps = {};

export default TicketRestaurant;
