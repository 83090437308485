import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

// Définir les styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '20px',
  },
  textType: {
    fontSize: 12,
    marginBottom: 10,
  },
  hr: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    marginVertical: 5,
  },
});

// Create Document Component
const SheetTrGrouped = (props) => {
  const { groupedTr, getVehicleName, getSpotName, today } = props;

  // Filtrer les tickets par date de livraison en amont
  const filteredGroupedTr = Object.keys(groupedTr).reduce((acc, charretteId) => {
    const spots = Object.keys(groupedTr[charretteId]).reduce((spotAcc, spotId) => {
      const tickets = groupedTr[charretteId][spotId].filter(ticket => moment(ticket.deliveryDate).format("YYYY-MM-DD") === today);
      if (tickets.length > 0) {
        spotAcc[spotId] = tickets;
      }
      return spotAcc;
    }, {});

    if (Object.keys(spots).length > 0) {
      acc[charretteId] = spots;
    }
    return acc;
  }, {});

  return (
    <Document>
      {Object.keys(filteredGroupedTr).map((charretteId) => (
        <Page key={charretteId} size="A4" style={styles.page}>
          <View>
            <Text>Ticket restaurant déclaré le {moment().format("DD/MM/YYYY")}</Text>
            <View style={styles.hr} />
            <Text>{getVehicleName(charretteId)}</Text>
            {Object.keys(filteredGroupedTr[charretteId]).map((spotId) => (
              <View key={spotId}>
                <Text style={styles.textType}>{getSpotName(spotId).toUpperCase()}</Text>
                {filteredGroupedTr[charretteId][spotId].map((ticket) => (
                  <View key={ticket.id}>
                    <Text style={styles.textType}>
                      {moment(ticket.deliveryDate).format("DD/MM/YYYY")} | {ticket.lastname} {ticket.firstname} | {ticket.email} | {ticket.ticket.amount / 100 + ' €'}
                    </Text>
                  </View>
                ))}
                <View style={styles.hr} />
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default SheetTrGrouped;