import React from "react";
import styled from "@emotion/styled";
import Loader from "views/universal/Loader/Loader";
import Filters from "./Filters";
import Customer from "./Customer";
import moment from "moment";
import { Table } from "react-bootstrap";
import PdfButton from "./PdfButton";

const DepositValidationPage = (props) => {
  const {
    establishment,
    vehicles,
    spots,
    trByCustomer,
    search,
    isLoadingPage,
    getCustomerPool,
    getCustomerAdvantage,
    tr
  } = props;

  
  // Fonction pour grouper par charretteId puis par spotId
  const groupTrByCharretteAndSpot = (ticketRestaurants) => {
    return ticketRestaurants.reduce((acc, ticketRestaurant) => {
      const { charretteId, spotId } = ticketRestaurant;
      if (!acc[charretteId]) {
        acc[charretteId] = {};
      }
      if (!acc[charretteId][spotId]) {
        acc[charretteId][spotId] = [];
      }
      acc[charretteId][spotId].push(ticketRestaurant);
      return acc;
    }, {});
  };

  const getVehicleName = (charretteId) => {
    const vehicle = tr.vehicles.find(vehicle => vehicle.id == charretteId);
    return vehicle ? vehicle.name : 'Nom de tournée inconnu';
  };

  const getSpotName = (spotId) => {
    const spot = tr.spots.find(spot => spot.id == spotId);
    return spot ? spot.name : 'Nom de point inconnu';
  };

  const groupedTr = groupTrByCharretteAndSpot(tr.ticketRestaurants);
  
  return (
    <>
      {isLoadingPage ? (
        <Loader items={2} />
      ) : (
        <>
          <div className="text-right mt-3">
            <PdfButton groupedTr={groupedTr} getVehicleName={getVehicleName} getSpotName={getSpotName} />
          </div>

          <Filters
            establishment={establishment}
            vehicles={vehicles}
            spots={spots}
          />

          {trByCustomer.length > 0 ? (
            <>
              {trByCustomer
                .filter(
                  (customer) =>
                    (search.vehicle === null ||
                      customer.charretteId === search.vehicle) &&
                    (search.spot === null || customer.spotId === search.spot) &&
                    (search.lastname === null ||
                      customer.lastname
                        .toLowerCase()
                        .includes(search.lastname.toLowerCase()))
                )
                .map((customer) => (
                  <Customer key={customer.customerId} customer={customer} getCustomerPool={getCustomerPool} getCustomerAdvantage={getCustomerAdvantage} />
                ))}
            </>
          ) : (
            <Help>
              <p>Il n'y a pas de Ticket Restaurant à traiter pour le moment.</p>
            </Help>
          )}
        </>
      )}
    </>
  );
};

const Help = styled.div`
  margin-top: 1.5rem;
  p {
    color: #bbb;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.l};
  }
`;

DepositValidationPage.propTypes = {};

DepositValidationPage.defaultProps = {};

export default DepositValidationPage;
